<template>
  <v-list shaped>
    <my-subheader :isMini="mini" :title="'UTAMA'"></my-subheader>

    <v-list-item link>
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>

      <v-list-item-title>Dashboard</v-list-item-title>
    </v-list-item>
    <v-list-group :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-file-arrow-up-down-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Data Dasar</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="(menu, i) in routes[1].children" :key="i" link>
        <v-list-item-icon>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menu.name }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <my-subheader :isMini="mini" :title="'SAMPLING'"></my-subheader>
    <v-list-group :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-database-search-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Data Sampling</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="(menu, i) in routes[2].children" :key="i" link>
        <v-list-item-icon>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menu.name }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-folder-search-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Data BRPLDAF</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="(menu, i) in routes[3].children" :key="i" link>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menu.name }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <my-subheader :isMini="mini" :title="'DATA MASTER'"></my-subheader>
    <v-list-group :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Staff</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(menu, i) in routes[4].children"
        :key="i"
        link
        @click="routerPush(menu)"
      >
        <v-list-item-icon>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menu.name }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-group :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-animation-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Komponen</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(menu, i) in routes[5].children"
        :key="i"
        link
        @click="routerPush(menu)"
      >
        <v-list-item-icon>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menu.name }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <my-subheader :isMini="mini" :title="'ADMINISTRATOR'"></my-subheader>
    <v-list-group :value="false" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pengaturan</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(menu, i) in routes[6].children"
        :key="i"
        link
        @click="routerPush(menu)"
      >
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menu.name }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "navmenu",
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
};
</script>
