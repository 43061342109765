<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const lightTheme = {
  themes: {
    light: {
      primary: "#ddddd",
      secondary: "#424242",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
    },
  },
};

const darkTheme = {
  themes: {
    dark: {
      primary: "#fffff",
      secondary: "#757575",
      accent: "#FF4081",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
    },
  },
};

const vuetify = new Vuetify({
  theme: {
    ...lightTheme,
    ...darkTheme,
  },
});

export default {
  name: "App",
  data: () => ({
    isDarkTheme: false,
  }),
  methods: {
    toggleTheme() {
      const theme = this.isDarkTheme ? darkTheme : lightTheme;
      vuetify.theme.setTheme(theme);
    },
  },
};

export { vuetify };
</script>

<style>
.v-input--selection-controls {
  margin-top: 5px !important;
  padding-top: 0px !important;
}

.breadcrumb-link {
  cursor: pointer;
}

.container--fluid {
  background: aliceblue;
}
</style>
