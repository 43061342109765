import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    icon: "mdi-home",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    icon: "mdi-home",
    component: HomeView,
    children: [
      //Utama
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: "mdi-home",
      },
      {
        path: "/pegawai",
        name: "Pegawai",
        icon: "mdi-home",
        component: () =>
          import(/* webpackChunkName: "pegawai" */ "../views/EmployeeView.vue"),
      },
      {
        path: "/data-dasr",
        name: "Data dasar",
        icon: "mdi-home",
        children: [
          {
            path: "/unggah-data-dasar",
            name: "Unggah Data Dasar",
            icon: "mdi-home",
            isOpen: false,
            // component: EmployeeView,
          },
          {
            path: "/input-data-dasar",
            name: "Input Data Dasar",
            icon: "mdi-home",
            isOpen: false,
            // component: EmployeeView,
          },
        ],
      },
      //Sampling
      {
        path: "/data-sampling",
        name: "Data Sampling",
        icon: "mdi-home",
      },
      {
        path: "/data-brpldaf",
        name: "Data BRPLDAF",
        icon: "mdi-home",
        children: [
          {
            path: "/data-pendaratan-brpldaf",
            name: "Data Pendaratan BRPLDAF",
            icon: "mdi-home",
            isOpen: false,
            // component: EmployeeView,
          },
          {
            path: "/data-operasional-brpldaf",
            name: "Data Operasional BRPLDAF",
            icon: "mdi-home",
            isOpen: false,
            // component: EmployeeView,
          },
          {
            path: "/biologi-brpldaf",
            name: "Biologi",
            icon: "mdi-home",
            isOpen: false,
            // component: EmployeeView,
          },
        ],
      },
      //Data Master
      {
        path: "/staf",
        name: "Staff",
        icon: "mdi-home",
      },
      //Administrator
      {
        path: "/pengaturan",
        name: "Pengaturan",
        icon: "mdi-cog",
      },
      {
        path: "/pegawai/input",
        name: "Input Pegawai ",
        icon: "mdi-home",
        component: () =>
          import(
            /* webpackChunkName: "pegawai_input" */ "../views/EmployeeInput.vue"
          ),
      },
      {
        path: "/enumerator",
        name: "Enumerator",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "enumerator" */ "../views/EnumeratorView.vue"
          ),
      },
      {
        path: "/enumerator/input",
        name: "Enumerator Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "enumerator_input" */ "../views/EnumeratorInput.vue"
          ),
      },
      {
        path: "/daerah-penangkapan",
        name: "Daerah Penangkapan",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "daerah-penangkapan" */ "../views/DaerahPenangkapanView.vue"
          ),
      },
      {
        path: "/daerah-penangkapan/input",
        name: "Daerah Penangkapan Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "daerah-penangkapan-input" */ "../views/DaerahPenangkapanInput.vue"
          ),
      },
      {
        path: "/sumberdaya",
        name: "Sumberdaya",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "sumberdaya" */ "../views/SumberdayaView.vue"
          ),
      },
      {
        path: "/sumberdaya/input",
        name: "Sumberdaya Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "sumberdaya-input" */ "../views/SumberdayaInput.vue"
          ),
      },
      {
        path: "/alat-tangkap",
        name: "Alat Tangkap",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "alat-tangkap" */ "../views/AlatTangkapView.vue"
          ),
      },
      {
        path: "/alat-tangkap/input",
        name: "Alat Tangkap Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "alat-tangkap-input" */ "../views/AlatTangkapInput.vue"
          ),
      },
      {
        path: "/organisasi",
        name: "Organisasi",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "organisasi" */ "../views/OrganisasiView.vue"
          ),
      },
      {
        path: "/organisasi/input",
        name: "Organisasi Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "organisasi-input" */ "../views/OrganisasiInput.vue"
          ),
      },
      {
        path: "/pengguna",
        name: "Pengguna",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "pengguna" */ "../views/PenggunaView.vue"
          ),
      },
      {
        path: "/pengguna/input",
        name: "Pengguna Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "pengguna-input" */ "../views/PenggunaInput.vue"
          ),
      },
      {
        path: "/pendaratan",
        name: "Pendaratan",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "pendaratan" */ "../views/PendaratanView.vue"
          ),
      },
      {
        path: "/pendaratan/input",
        name: "Pendaratan Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "pendaratan-input" */ "../views/PendaratanInput.vue"
          ),
      },
      {
        path: "/operasional",
        name: "Operasional",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "operasional" */ "../views/OperasionalView.vue"
          ),
      },
      {
        path: "/operasional/input",
        name: "Operasional Input",
        icon: "mdi-home",
        isOpen: false,
        component: () =>
          import(
            /* webpackChunkName: "operasional-input" */ "../views/OperasionalInput.vue"
          ),
      },
    ],
  },
  {
    path: "*",
    name: "not-found",
    icon: "mdi-home",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
