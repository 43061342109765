<template>
  <v-layout>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <div class="text-sm-h6 text-subtitle">SISTEM BRPL</div>
      </div>
      <v-spacer></v-spacer>

      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-menu top offset-x :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
              <v-icon> mdi-menu </v-icon>
            </v-btn>
          </template>

          <my-menu :routes="routes"></my-menu>
        </v-menu>
      </div>
      <div v-else>
        <v-menu top offset-y rounded="l">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-4"
              small
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2"> mdi-cloud-download </v-icon>
              Download Form
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in formDoc"
              :key="index"
              link
              :href="item.link"
              target="_blank"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
              <v-icon class="mr-2"> mdi-file-document-outline </v-icon>
              SOP & Panduan
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in sopDoc"
              :key="index"
              link
              :href="item.link"
              target="_blank"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          class="mx- elevation-0"
          fab
          dark
          small
          color="primary"
          @click="logout"
        >
          <v-icon dark> mdi-power </v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            alt="My Image"
            class="shrink"
            contain
            :src="require('@/assets/logokkp.png')"
            transition="scale-transition"
            width="40"
          />
        </v-list-item-avatar>

        <v-list-item-title>BRPL Admin</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list shaped>
        <my-subheader :isMini="mini" :title="'UTAMA'"></my-subheader>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-file-arrow-up-down-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Data Dasar</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(menu, i) in routes[1].children" :key="i" link>
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <my-subheader :isMini="mini" :title="'SAMPLING'"></my-subheader>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-database-search-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Data Sampling</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(menu, i) in routes[2].children"
            :key="i"
            link
            @click="routerPush(menu)"
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item>

          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Biologi</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              class="biologi"
              v-for="(menu, i) in routes[7].children"
              :key="i"
              link
              @click="routerPush(menu)"
            >
              <v-list-item-title>{{ menu.name }}</v-list-item-title>

              <v-list-item-icon>
                <v-icon></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-folder-search-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Data BRPLDAF</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(menu, i) in routes[3].children"
            :key="i"
            link
            @click="routerPush(menu)"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <my-subheader :isMini="mini" :title="'DATA MASTER'"></my-subheader>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Staff</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(menu, i) in routes[4].children"
            :key="i"
            link
            @click="routerPush(menu)"
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-animation-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Komponen</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(menu, i) in routes[5].children"
            :key="i"
            link
            @click="routerPush(menu)"
          >
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <my-subheader :isMini="mini" :title="'ADMINISTRATOR'"></my-subheader>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pengaturan</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(menu, i) in routes[6].children"
            :key="i"
            link
            @click="routerPush(menu)"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ menu.name }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view refs="router-home"></router-view>
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>
import mySubheader from "@/components/SubheaderMenu.vue";
import myMenu from "@/components/NavMenu.vue";
import menuList from "../etc/menuList";
export default {
  name: "HomeView",
  components: {
    mySubheader,
    myMenu,
  },
  data() {
    return {
      drawer: true,
      routes: [],
      mini: true,
      formDoc: [
        {
          title: "Download Form Pendaratan",
          link: `${process.env.VUE_APP_API_URL}/format/pendaratan.xlsx`,
        },
        {
          title: "Download Form Operasional",
          link: `${process.env.VUE_APP_API_URL}/format/operasional.xlsx`,
        },
        {
          title: "Download Form Biologi Ukuran",
          link: `${process.env.VUE_APP_API_URL}/format/biologiukuran.xlsx`,
        },
        {
          title: "Download Form Biologi Reproduksi",
          link: `${process.env.VUE_APP_API_URL}/format/biologireproduksi.xlsx`,
        },
      ],
      sopDoc: [
        {
          title: "Download SOP",
          link: `${process.env.VUE_APP_API_URL}/sop.pdf`,
        },
        {
          title: "Download Panduan Manual",
          link: `${process.env.VUE_APP_API_URL}/panduanmanual.pdf`,
        },
        {
          title: "Download Panduan Excel",
          link: `${process.env.VUE_APP_API_URL}/panduanexcel.pdf`,
        },
        {
          title: "Download Panduan Sistem",
          link: `${process.env.VUE_APP_API_URL}/panduansistem.pdf`,
        },
      ],
      offset: true,
    };
  },
  created() {
    this.routes = menuList;
  },
  methods: {
    routerPush(v) {
      this.$router.push(v.path);
    },

    logout() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.v-application--is-ltr
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 52px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 15px !important;
}

.v-list-item__title {
  font-size: 0.8rem;
}

.v-subheader {
  height: 28px;
  font-size: 0.7rem;
}

.biologi {
  padding-left: 19px;
}
</style>
