import axios from "axios";

// export const baseUrl = "http://localhost:4400/";
export const baseUrl = "http://139.162.62.244:4300/";
const http = axios.create({
  baseURL: baseUrl,
  // timeout: 5000,
  headers: {
    Authorization: "Basic ZmF5YXF1bjpzYXRyaWE=",
    "Content-Type": "application/json",
  },
});

export default http;
