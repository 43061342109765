import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2C3E50",
        secondary: "#F39C12",
        accent: "#3498DB",
        error: "#E74C3C",
        warning: "#F1C40F",
        info: "#1ABC9C",
        success: "#2ECC71",
      },
      dark: {
        primary: "#2196F3",
        secondary: "#757575",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});

export default vuetify;
