const menuList = [
  //Utama
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "mdi-home",
    // component: EmployeeView,
  },
  {
    path: "/data-dasr",
    name: "Data dasar",
    icon: "mdi-file-arrow-up-down-outline",
    children: [
      {
        path: "/unggah-data-dasar",
        name: "Unggah Data Dasar",
        icon: "mdi-file-document-edit-outline",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/input-data-dasar",
        name: "Input Data Dasar",
        icon: "mdi-file-download-outline",
        isOpen: false,
        // component: EmployeeView,
      },
    ],
  },
  //Sampling
  {
    path: "/data-sampling",
    name: "Data Sampling",
    icon: "mdi-home",
    children: [
      {
        path: "/pendaratan",
        name: "Pendaratan",
        icon: "mdi-airplane-landing",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/operasional",
        name: "Operasional",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
    ],
  },
  {
    path: "/data-brpldaf",
    name: "Data BRPLDAF",
    icon: "mdi-home",
    children: [
      {
        path: "/data-pendaratan-brpldaf",
        name: "Data Pendaratan BRPLDAF",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/data-operasional-brpldaf",
        name: "Data Operasional BRPLDAF",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/biologi-brpldaf",
        name: "Biologi",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
    ],
  },
  //Data Master
  {
    path: "/staf",
    name: "Staff",
    icon: "mdi-home",
    children: [
      {
        path: "/pegawai",
        name: "Pegawai",
        icon: "mdi-home",
      },
      {
        path: "/enumerator",
        name: "Enumerator",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
    ],
  },
  {
    path: "/komponen",
    name: "Komponen",
    icon: "mdi-home",
    children: [
      {
        path: "/daerah-penangkapan",
        name: "Daerah Penangkapan",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/sumberdaya",
        name: "Sumber Daya",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/alat-tangkap",
        name: "Alat Tangkap",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
    ],
  },
  //Administrator
  {
    path: "/pengaturan",
    name: "Pengaturan",
    icon: "mdi-cog",
    children: [
      {
        path: "/organisasi",
        name: "Organisasi",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/pengguna",
        name: "Pengguna",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
    ],
  },
  //Data sampling biologi
  {
    // path: "/biologi",
    name: "Biologi",
    icon: "mdi-home",
    isOpen: false,
    // component: EmployeeView,
    children: [
      {
        path: "/ukuran",
        name: "Ukuran",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
      {
        path: "/reproduksi",
        name: "Reproduksi",
        icon: "mdi-home",
        isOpen: false,
        // component: EmployeeView,
      },
    ],
  },
];

export default menuList;
