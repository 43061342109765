<template>
  <v-subheader v-if="!isMini">{{ title }}</v-subheader>

  <v-subheader v-else class="d-flex align-center"
    ><v-icon class="mdi mdi-dots-horizontal"></v-icon
  ></v-subheader>
</template>

<script>
export default {
  name: "mySubheader",
  props: {
    title: String,
    isMini: Boolean,
  },
};
</script>
